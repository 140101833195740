import React, { useMemo } from 'react'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { Separator } from '@/shared/ui/Separator'
import { standardQuoteService, getSQValuesFromQuote } from '@/pages/Order/ui/OrderQuote/lib'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { useAppSelector } from '@/app/store'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { RfqService } from '@/entities/rfq/model'

type OrderQuoteBreakdownProps = {
    quote: Quote
}

export const OrderQuoteBreakdown = ({ quote }: OrderQuoteBreakdownProps) => {
    const { state: userState } = useUserState()
    const isSupplyAccount = userState.userInfo?.role === UserRole.WAREHOUSE_MANAGER

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const isStandardQuote = quote.type === QuoteType.STANDARD

    const services = useMemo(() => {
        if (!isStandardQuote) return null
        return getSQValuesFromQuote({
            order,
            quote,
            userType: isSupplyAccount ? 'supply' : 'demand',
            mode: 'read',
        })
    }, [order, quote])

    const quotePrice = isSupplyAccount ? quote.price : quote.sellPrice

    if (!isStandardQuote)
        return (
            <div className="space-y-3">
                <div className="flex items-center w-full md:w-fit">
                    <span className="text-sm text-foreground-secondary font-normal">Estimated Total:</span>
                    {quotePrice ? (
                        <span className="ml-1 text-xl text-primary font-semibold">
                            $
                            {quotePrice.toLocaleString('en', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </span>
                    ) : (
                        <span className="ml-1 text-foreground-secondary font-medium">N/A</span>
                    )}
                </div>
                {isSupplyAccount && (
                    <React.Fragment>
                        <Separator />
                        <div className="break-word">
                            {quote.notes?.split('\n').map((s, index) => (
                                <p key={index} className="min-h-[1.5rem] text-base">
                                    {s}
                                </p>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </div>
        )

    return (
        <div className="sm:space-y-3">
            <div className="hidden sm:flex flex-row gap-3 gap-y-0 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                <div className="sm:w-[100px] lg:w-[170px] flex items-center text-sm font-medium shrink-0 self-start">
                    Service Requested
                </div>
                <Separator orientation="vertical" className="block h-auto self-stretch" />
                <div className="text-sm font-medium">Pricing</div>
            </div>

            {Object.entries(services!).map(([key, service], index) => {
                const Comp = standardQuoteService[key as RfqService]?.breakdownComponent
                if (!Comp) return null

                return (
                    <React.Fragment key={index}>
                        <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                            <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                                {service.name}
                            </div>
                            <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                            <Comp order={order} service={service} />
                        </div>
                        <Separator />
                    </React.Fragment>
                )
            })}

            <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                    Minimum Charge
                </div>
                <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                {quotePrice ? (
                    <div className="text-xl leading-6 text-primary font-semibold">
                        $
                        {quotePrice.toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </div>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>

            {isSupplyAccount && quote.notes ? (
                <>
                    <Separator />
                    <div className="flex flex-col sm:flex-row gap-x-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                        <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                            Notes
                        </div>
                        <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                        <div className="mt-2 break-word">
                            {quote.notes?.split('\n').map((s, index) => (
                                <p key={index} className="min-h-[1.5rem] text-base">
                                    {s}
                                </p>
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}
