import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PopoverArrow, PopoverContent, PopoverRoot, PopoverTrigger, PopoverTriggerWrapper } from '@/shared/ui/Popover'
import { InBell } from '@/shared/ui/Icons/InBell'
import { Button } from '@/shared/ui/Button'
import { Badge } from '@/shared/ui/Badge'
import { Avatar, AvatarFallback, AvatarImage } from '@/shared/ui/Avatar'
import dayjs from 'dayjs'
import cn from 'classnames'
import { useNotifications } from '@/entities/notifications/api/useNotifications'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { notificationsActions } from '@/entities/notifications/model/slice'
import { NotificationDto } from '@/entities/notifications/model/dto'
import { useNavigate } from 'react-router-dom'
import logo from 'images/logocircle.png'
import './index.scss'

export const NotificationRow: React.FC<
    PropsWithChildren<NotificationDto> & {
        onRead: (id: number) => void
        close: () => void
    }
> = ({ title, message, createdAt, read, id, type, close, sender, entityId, onRead }) => {
    const clickable = !read || (entityId && type)

    const navigate = useNavigate()
    const navigateTo = useCallback(() => {
        if (type === 'chat') {
            navigate(`/order/${entityId}`)
            close()
        }
    }, [entityId, navigate, type])
    const handleClick = useCallback(
        (event) => {
            if (!read && onRead) {
                onRead(id)
            }
            if (entityId && type) {
                navigateTo()
            }
        },
        [read, onRead, entityId, type, id, navigateTo],
    )
    return (
        <div
            className={cn('notification-row flex items-stretch border-b border-gray-2 px-4 py-3', {
                'bg-gray-1': !read,
                'cursor-pointer hover:bg-gray-2': clickable,
            })}
            onClick={handleClick}
        >
            <div className="notification-row__icon mr-4 flex items-start justify-center">
                {/*    random avatar */}
                <div className="flex flex-col justify-center items-center min-w-[60px]">
                    <Avatar className="w-8 h-8" placeholder="UX">
                        {sender?.olimp && <AvatarImage src={logo} alt="OLIMP" />}
                        <AvatarFallback className="text-[10px] text-black bg-gray-2">
                            {sender?.shortName ?? 'OLIMP'}
                        </AvatarFallback>
                    </Avatar>
                    {sender && (
                        <span
                            className="text-gray-500 text-xs line-clamp-2 pt-1
                    overflow-hidden text-ellipsis whitespace-nowrap w-[60px] relative block"
                            title={sender.name}
                        >
                            {sender.name}
                        </span>
                    )}
                </div>
            </div>

            <div className="notification-row__content">
                <div
                    className={cn('notification-row__title text-black', {
                        'text-primary font-semibold': !read,
                    })}
                >
                    {title}
                </div>
                <div className="notification-row__description text-[14px] max-w-[350px] pr-2">{message}</div>
                {/*make middle dot symbol */}
                <div className="notification-row__time text-gray-500 text-sm">
                    {dayjs(createdAt).fromNow()} • <b>{entityId}</b>
                </div>
            </div>
            <div className="notification-row__actions ml-auto">
                {/*    small colored dot in the middle */}
                {!read && <Badge className="circle px-1 py-1" variant="default"></Badge>}
            </div>
        </div>
    )
}
const InfiniteScroller: React.FC<{ onReachEnd: (e: any) => void; maxHeight: string | number }> = ({
    onReachEnd,
    children,
    maxHeight,
}) => {
    const scrollViewportRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const current = scrollViewportRef.current
        if (current) {
            current.addEventListener('scroll', onReachEnd)
            return () => {
                current.removeEventListener('scroll', onReachEnd)
            }
        }
    }, [onReachEnd])

    return (
        <ScrollArea
            className="user-notifications__list"
            viewportProps={{ ref: scrollViewportRef, style: { maxHeight } }}
        >
            <div className="">{children}</div>
        </ScrollArea>
    )
}

export const UserNotificationsPopover: React.FC<{
    loadMore: any
    markRead: (id: number) => void
    markAllRead: () => void
    mobile?: boolean
}> = ({ loadMore, markRead, markAllRead, mobile }) => {
    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    const totalUnread = useAppSelector((state) => state.notifications.unreadNotifications)
    const notifications = useAppSelector((state) => state.notifications.notifications)
    const loading = useAppSelector((state) => state.notifications.notificationsLoading)
    const hasMore = useAppSelector((state) => state.notifications.hasMoreNotifications)

    const hasMoreRef = useRef(hasMore)
    const handleLoadMore = useCallback(() => {
        if (!hasMoreRef.current || loading) return
        loadMore()
    }, [loading, loadMore])

    const handleScroll = useCallback(
        (e: any) => {
            const current = e.target
            if (current) {
                const { scrollTop, scrollHeight, clientHeight } = current
                if (Math.round(scrollTop) + clientHeight + 5 >= scrollHeight) {
                    handleLoadMore()
                }
            }
        },
        [handleLoadMore],
    )

    useEffect(() => {
        hasMoreRef.current = hasMore
    }, [hasMore])

    return (
        <PopoverRoot open={open} onOpenChange={(op) => setOpen(op)}>
            <PopoverTriggerWrapper>
                <PopoverTrigger asChild>
                    <Button className="user-notifications__button relative mr-1" variant="ghost">
                        <InBell className={mobile ? 'w-5' : undefined} />

                        {totalUnread > 0 && (
                            <Badge className="circle px-1.5 absolute top-0 right-0.5" variant="danger" color="red">
                                {totalUnread}
                            </Badge>
                        )}
                    </Button>
                </PopoverTrigger>
            </PopoverTriggerWrapper>
            <PopoverContent
                className={cn('p-0 border-gray-2', {
                    'w-[100vw]': mobile,
                    'max-w-[30vw] min-w-[20vw]': !mobile,
                })}
            >
                <PopoverArrow />

                <div className={'user-notifications__header pl-4 pr-4 pt-4 pb-3 border-b flex justify-between'}>
                    <span className="text-black font-semibold">Notifications</span>
                    {totalUnread > 0 && (
                        <Button
                            variant="ghost"
                            className="text-primary h-6 !text-sm hover:bg-gray-1"
                            onClick={markAllRead}
                        >
                            Mark all as read
                        </Button>
                    )}
                </div>
                <InfiniteScroller onReachEnd={handleScroll} maxHeight={mobile ? '50vh' : 220}>
                    {notifications.map((notification) => (
                        <NotificationRow
                            key={notification.id}
                            {...notification}
                            onRead={markRead}
                            close={() => setOpen(false)}
                        />
                    ))}

                    {!loading && notifications.length === 0 && (
                        <div className="flex flex-grow h-[200px] justify-center items-center text-gray-500 text-sm py-2">
                            No notifications
                        </div>
                    )}

                    <div className="flex justify-center items-center text-gray-500 text-sm py-2">
                        {loading ? 'Loading...' : hasMore ? 'Load more' : ''}
                    </div>
                </InfiniteScroller>
            </PopoverContent>
        </PopoverRoot>
    )
}

export const UserNotifications: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
    const dispatch = useAppDispatch()

    const notifications = useAppSelector((state) => state.notifications.notifications)
    const hasMore = useAppSelector((state) => state.notifications.hasMoreNotifications)

    const { connected, getNotifications, markSeen, markAllSeen } = useNotifications({
        callbacks: {
            onNotificationReceived: (notification) => {
                dispatch(notificationsActions.addNotification(notification))
            },
            onNotificationsLoaded: (response) => {
                if (response.unread) {
                    dispatch(notificationsActions.setUnreadNotifications(response.unread))
                }
                dispatch(notificationsActions.setNotifications(response.notifications))
                dispatch(notificationsActions.setNotificationsLoading(false))
                dispatch(notificationsActions.setHasMoreNotifications(response.hasMore))
            },
        },
    })

    const loadMore = () => {
        if (hasMore) {
            dispatch(notificationsActions.setNotificationsLoading(true))

            getNotifications(notifications.length)
        }
    }

    const markRead = (id: number) => {
        dispatch(notificationsActions.markAsRead(id))

        markSeen(id)
    }

    const markAllRead = () => {
        dispatch(notificationsActions.markAllAsRead())

        markAllSeen()
    }

    useEffect(() => {
        if (connected) {
            getNotifications()
        }
    }, [connected])

    return (
        <div className="user-notifications flex justify-center items-center">
            <UserNotificationsPopover
                loadMore={loadMore}
                markRead={markRead}
                markAllRead={markAllRead}
                mobile={mobile}
            />
        </div>
    )
}
